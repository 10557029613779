import request from "../libs/request";
import EntityService from "./entity";

class ExhibitCategoryService extends EntityService {
  constructor(name) {
    super(name);
  }
  async getEntityPage (exhibitCategory, pageable) {
    const result = await request({
      url: `/exhibit-categories`,
      method: "get",
      params: {
        ...exhibitCategory,
        ...pageable,
      },
    });

    return result;
  }
}

export default new ExhibitCategoryService("exhibit-category");