<template>
  <div class="pane-wrapper">
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="service"
        :entity="entity"
        :filters="privateFilters"
        :sorter="sorter"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleDetailClick(action.record)">
            详情
          </a-button>
          <a-button type="link" @click="handleEditClick(action.record)">
            编辑
          </a-button>
          <a-popconfirm
            title="是否确认删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteClick(action.record)"
          >
            <a-button type="link"> 删除 </a-button>
          </a-popconfirm>
        </template>
      </y-table>
    </div>
    <editor
      :visible.sync="visible"
      :target="target"
      @close="handleEditorClose"
    ></editor>
  </div>
</template>

<script>
import ExhibitService from "@/services/exhibit";

import Editor from "../editor";
const defaultFilters = {
  type: "SUPPLY",
};
const defaultEntity = {
  properties: {},
};
export default {
  components: { Editor },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
    sorter: {
      type: Array,
    },
    entity: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(defaultEntity));
      },
    },
  },
  data() {
    return {
      service: ExhibitService,
      visible: false,
      visibleSortModal: false,
      privateFilters: JSON.parse(JSON.stringify(defaultFilters)),
      target: JSON.parse(JSON.stringify(defaultEntity)),
      columns: [
        {
          dataIndex: "properties.cover",
          title: "封面图",
          scopedSlots: { customRender: "picture" },
        },
        {
          dataIndex: "name",
          title: "名称",
        },
        {
          dataIndex: "exhibitor.name",
          title: "展商",
          customRender: (text) => {
            return <y-tag value={text}></y-tag>;
          },
        },
        {
          dataIndex: "categories",
          title: "分类",
          customRender: (text) => {
            return text.map((t) => <y-tag value={t.name}></y-tag>);
          },
        },
        {
          dataIndex: "document.content",
          title: "介绍",
          scopedSlots: { customRender: "text" },
        },
        {
          dataIndex: "description",
          title: "简介",
          scopedSlots: { customRender: "text" },
        },
        {
          dataIndex: "properties.video",
          title: "视频",
          scopedSlots: { customRender: "video" },
        },
        // {
        //   dataIndex: "favoriteNumber",
        //   title: "点赞",
        // },
        {
          dataIndex: "collectNumber",
          title: "收藏",
          customRender: (text) => {
            return <span>{text || 0}</span>;
          },
          sorter: true,
        },
        {
          dataIndex: "browseNumber",
          title: "浏览",
          customRender: (text) => {
            return <span>{text || 0}</span>;
          },
          sorter: true,
        },
        {
          dataIndex: "sequence",
          title: "权重",
          scopedSlots: { customRender: "sequence" },
          sorter: true,
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
          sorter: true,
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
          sorter: true,
        },
        {
          dataIndex: "enabled",
          title: "状态",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
   watch: {
    filters: {
      handler: async function (val) {
        this.privateFilters = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClickAdd() {
      this.target = JSON.parse(JSON.stringify(defaultEntity));
      this.visible = true;
    },
    handleDetailClick(record) {
      this.$router.push({ path: `/exhibit/${record.id}` });
    },
    handleEditClick(record) {
      this.target = record;
      this.visible = true;
    },
    handleEditorClose() {
      this.target = JSON.parse(JSON.stringify(defaultEntity));
      this.privateFilters = JSON.parse(JSON.stringify( this.filters));
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await this.service.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.privateFilters = JSON.parse(JSON.stringify( this.filters));
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>